import React, { useState, useEffect, useContext } from "react";
import "./style/news.css";
import NewsCard from "../../components/cardOfNews/NewsCard";
import { useParams } from "react-router-dom";
import LatestPosts from "./../../components/latestPosts/LatestPosts";
import axios from "axios";
import { apiUrl } from "./../../constants/index";
import { AppContext } from "../../context/modeContext";
import Pagination from "react-bootstrap/Pagination";
import { FaBedPulse } from "react-icons/fa6";

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { category } = useParams();
  const token = localStorage.getItem("token");
  const { mode } = useContext(AppContext);

  // console.log(category);

  const getPosts = async () => {
    setLoading(true);
    console.log("get posts");
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/posts?category=${category}&limit=9&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("news response: ", response);
      if (response.data) {
        setLoading(false);
        setNews(response.data.data.posts);
      } else {
        // console.log("error in response");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.error("error calling employees api");
    }
  };

  useEffect(() => {
    getPosts();
  }, [category,page]);

  return (
    <div className="container">
      <div className="newsTitle">
        {category === "international" && <h1>أخبار دولية</h1>}
        {category === "local" && <h1>أخبار محلية</h1>}
        {category === "arab" && <h1>أخبار عربية</h1>}
        {category === "sports" && <h1>أخبار رياضية</h1>}
        {category === "Cultural" && <h1>أخبار ثقافية</h1>}
        {category === "organization" && <h1>أخبار منظمة ذوي الإعاقة</h1>}
        {category === "inspiringPersonalities" && <h1>شخصيات ملهمة</h1>}
      </div>

      {loading ? (
        <h1
          className="my-5 mx-auto text-center"
          style={{ color: mode === "dark" ? "white" : "dark" }}
        >
          جاري التحميل
        </h1>
      ) : news.length > 0 ? (
        <div className="row allPosts">
          {news.map((post, index) => (
            <div className="col-sm-4 mx-auto my-4" key={index}>
              <NewsCard
                id={post._id}
                title={post.title}
                image={post.image}
                description={post.summary}
              />
            </div>
          ))}
        </div>
      ) : (
        <h1
          className="my-5 mx-auto text-center"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          لا يوجد أخبار
        </h1>
      )}

      <div className="pagination text-center">
        <Pagination className="mx-auto my-2 text-center">
          <Pagination.Item className="pagination-item" onClick={() => setPage(prevPage => prevPage + 1)} disabled={news.length === 0 ? true : false}>الأقدم</Pagination.Item>
          <Pagination.Item className="pagination-item" onClick={() => setPage(page)}>{page}</Pagination.Item>
          <Pagination.Item className="pagination-item" onClick={() => setPage(prevPage => prevPage - 1)} disabled={page <= 1 ? true : false}>الأحدث</Pagination.Item>
        </Pagination>
      </div>

      <section className="latestPosts">
        <LatestPosts />
      </section>
    </div>
  );
};

export default News;
