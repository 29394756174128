import React, { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./style/nav.css";
import logo from "../../imgs/dpologo.png";
import TopBar from "../tobBar/TopBar";
import { AppContext } from "../../context/modeContext";
import { Link } from "react-router-dom"; 

const NavBar = () => {
  const { mode } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container">
      <div className="top-bar">
        <TopBar />
      </div>
      <Navbar expand="lg" className={`nav-bar ${mode}`}>
        <Container fluid>
          <Navbar.Brand>
            <img src={logo} alt="logo" style={{width:"150px", height:"100px"}}/>
          </Navbar.Brand>

          {/* Toggle button with conditional styling */}
          <Navbar.Toggle
            onClick={handleShow}
            aria-controls="offcanvasNavbar"
            style={{
              backgroundColor: mode === "dark" ? "#ffffff" : "#2163e8",
              color: mode === "dark" ? "#000" : "#fff",
              border: "none",
            }}
          />

          <Navbar.Collapse id="offcanvasNavbar">
            <Nav className="m-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link className={mode === "light" ? "link" : "linkDark"} onClick={handleClose}>
                <Link to={"/"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>الرئيسية</Link>
              </Nav.Link>
              <NavDropdown title="أخبار" id="navbarScrollingDropdown" className={mode === "light" ? "link" : "linkDark"}>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/local"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار محلية</Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/arab"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار عربية</Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/international"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار دولية</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="أخبار عامة" id="navbarScrollingDropdown" className={mode === "light" ? "link" : "linkDark"}>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/sports"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار رياضية</Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/Cultural"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار ثقافية</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="شخصيات" id="navbarScrollingDropdown" className={mode === "light" ? "link" : "linkDark"}>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/talks"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>كلمات رواد التنمية</Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleClose}>
                  <Link to={"/news/inspiringPersonalities"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>شخصيات ملهمة</Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className={mode === "light" ? "link" : "linkDark"} onClick={handleClose}>
                <Link to={"/disabled-people-ads"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>إعلانات لذوي الإعاقة</Link>
              </Nav.Link>
              <Nav.Link className={mode === "light" ? "link" : "linkDark"} onClick={handleClose}>
                <Link to={"/news/organization"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>أخبار منظمات ذوي الإعاقة</Link>
              </Nav.Link>
              <Nav.Link className={mode === "light" ? "link" : "linkDark"} onClick={handleClose}>
                <Link to={"/contact-us"} className={mode === "light" ? "innerLink" : "innerLinkDark"}>تواصل</Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
