import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import "./style/createPost.css";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate } from "react-router-dom";
// import Alert from 'react-bootstrap/Alert';

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [category, setCategory] = useState("local");
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const navigate = useNavigate()

  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("img response", response.data);
      setImage(response.data.file.path); // Update this based on your backend's response
    } catch (error) {
      console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const sendPost = async () => {
    console.log("send post");
    setLoading(true);
    if (title.length === 0) {
      setError("يجب إدخال عنوان الخبر");
      setLoading(false)
    } else if (summary.length === 0) {
      setError("يجب إدخال ملخص الخبر");
      setLoading(false)
    } else if (description.length === 0) {
      setError("يجب إدخال تفاصيل الخبر");
      setLoading(false)
    } else if (category.length === 0) {
      setError("يجب إدخال القسم");
      setLoading(false)
    } else if (image.length === 0) {
      setError("يجب إدخال الصورة");
      setLoading(false)
    } else {
      setError("")
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/posts/create-post`,
          {
            title,
            summary,
            body: description,
            image,
            category,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("create post", response);
        if (response.data) {
          setLoading(false);
          alert("تمت الإضافة بنجاح");
          navigate("/posts-dashboard")
        } else {
          setLoading(false);
          alert("هناك خطأ اعد إدخال المعلومات");
        }
      } catch (error) {
        // console.error("error creating post", error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>إنشاء خبر</h1>
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              عنوان الخبر
            </label>
            <Input
              type={"text"}
              value={title}
              onchange={(e) => setTitle(e.target.value)}
              placeholder={"حدث "}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="d-block "
            >
              القسم
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              onChange={(e) => setCategory(e.target.value)}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={"local"}>محلية</option>
              <option value={"international"}>عالمية</option>
              <option value={"arab"}>عربية</option>
              <option value={"sports"}>رياضية</option>
              <option value={"Cultural"}>ثقافية</option>
              <option value={"inspiringPersonalities"}>شخصيات ملهمة</option>
              <option value={"organization"}>منظمات ذوي الإعاقة</option>
            </select>
          </div>
        </div>
 
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              ملخص  الخبر
            </label>
            <div className="area">
              <textarea
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                placeholder="ملخص  الخبر ...."
                style={{
                  backgroundColor: "rgba(33, 99, 232, 0.2)",
                  color: mode === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              تفاصيل  الخبر
            </label>
            <div className="area">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="تفاصيل الخبر ......."
                style={{
                  backgroundColor: "rgba(33, 99, 232, 0.2)",
                  color: mode === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              صورة عن الخبر
            </label>
            <Input
              type="file"
              onchange={handleFileChange}
              placeholder={"صورة  عن الخبر"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
      </div>

      {error.length > 0 && <h4 className="text-danger text-center my-3 mx-auto">{error}</h4>}

      <div className="createBtn">
        <button onClick={sendPost}>{loading ? "جاري الإضافة" : "إضافة"}</button>
      </div>
    </div>
  );
};

export default CreatePost;
