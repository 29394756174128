import axios from "axios";
import React, { useEffect, useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import { GrFormPreviousLink } from "react-icons/gr";
import "./style/trend.css";

const TrendingNews = () => {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getNews = async () => {
    try {
      const response = await axios.get("https://api.dpo-news.com/api/v1/trendingNews")
      // console.log("all news:",  response);
      setNews(response.data)
    } catch (error) {
      // console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    if (news.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [news]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? news.length - 1 : prevIndex - 1
    );
  };

  // console.log("news", news)

  return (
    <div className="newsApi container">
      <div className="newsContent">
        <div className="right">
          <div >
            <h5 className="trendingTitle">أحدث الأخبار</h5>
          </div>
          <div className="newsText">
            {news.length > 0 ? (
              <a href={news.url}>{news[currentIndex]?.title}</a>
            ) : (
              <h6>لا يوجد اخبار حاليا</h6>
            )}
          </div>
        </div>
        <div className="transform">
          <span onClick={handleNext}>
            <GrFormNextLink size={25} />
          </span>
          <span onClick={handlePrevious}>
            <GrFormPreviousLink size={25} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrendingNews;
