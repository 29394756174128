import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import { AppContext } from "../../context/modeContext";
import "./style/createTalks.css";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate } from "react-router-dom";

const CreateTalks = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [talk, setTalk] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const navigate = useNavigate()
  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("img response", response.data);
      setImage(response.data.file.path); // Update this based on your backend's response
    } catch (error) {
      // console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const createTalk = async () => {
    console.log("create talk");
    setLoading(true);
    if (name.length === 0) {
      setError("يرجي إدخال اسم الشخصية");
      setLoading(false);
    } else if (talk.length === 0) {
      setError("يرجي إدخال الكلمة");
      setLoading(false);
    } else if (image.length === 0) {
      setError("يرجي إدخال الصورة");
      setLoading(false);
    } else {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/pioneerWords/create-pioneerWord`,
          {
            name,
            body: talk,
            image,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("create talk response", response);
        if (response.data) {
          setLoading(false);
          alert("تمت الإضافة بنجاح");
          setError("")
          navigate("/talks-view")
        } else {
          setError("")
          setLoading(false);
          alert("هناك خطأ حاول مرة أخرى");
        }
      } catch (error) {
        setError("")
        setLoading(false);
        console.error("error fetching create talk", error);
        alert("هناك خطأ حاول مرة أخرى وقت لاحق");
      }
    }
  };

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>انشاء كلمة</h1>
      
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              اسم الشخصية
            </label>
            <Input
              type={"text"}
              value={name}
              onchange={(e) => setName(e.target.value)}
              placeholder={"اسم الشخصية"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              صورة صاحب الكلمة
            </label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={"صورة شخص"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الكلمة
            </label>
            <div className="area">
              <textarea
                value={talk}
                onChange={(e) => setTalk(e.target.value)}
                placeholder="الكلمة"
                style={{
                  backgroundColor: "rgba(33, 99, 232, 0.2)",
                  color: mode === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      
      {error.length > 0 && <h4 className="text-danger text-center my-3 mx-auto">{error}</h4>}

      <div className="createBtn">
        <button onClick={createTalk}>
          {loading ? "جاري الإضافة" : "إضافة"}
        </button>
      </div>
    </div>
  );
};

export default CreateTalks;
