import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import "./style/adsTable.css";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "../../constants";

const AdsTable = ({ columns, values, onClickDelete, onClickUpdate }) => {
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");

  const deletePost = async (id) => {
    console.log("delete post function");
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/companyAds/delete-companyAd/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("delete response", response);
      if (response.data) {
        alert("تم حذف الإعلان");
        window.location.reload();
      }
    } catch (error) {
      // console.error("error call deleting", error);
    }
  };

  return (
    <div className="adminTable">
      <Table
        striped
        bordered
        hover
        variant={mode === "dark" ? "dark" : "light"}
      >
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th id="th" key={index}>
                <h6>{col}</h6>
              </th>
            ))}
          </tr>
        </thead>
        {values.length > 0 && (
          <tbody>
            {values.map((val) => {
              return (
                <tr key={val.id}>
                  <td>{val.companyName}</td>
                  <td>
                    <img
                      className="tableImg"
                      src={val.image}
                      alt="صورة الخبر"
                    />
                  </td>
                  <td>
                    <div className="icons">
                      <button
                        onClick={() => {
                          deletePost(val._id);
                          onClickDelete();
                        }}
                      >
                        مسح
                      </button>
                      {/* <MdDelete
                       size={25}
                       className="delIcon"
                       onClick={() => {
                         deletePost(val._id);
                         onClickDelete();
                       }}
                     /> */}
                      {/* <FaEdit
                       size={25}
                       className="updIcon"
                       onClick={onClickUpdate}
                     /> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
        {values.length === 0 && "لا يوجد إعلانات"}
      </Table>
    </div>
  );
};

export default AdsTable;
