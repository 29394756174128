import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style/adsDisView.css";
import { AppContext } from "../../../context/modeContext";
import AdsTable from "../../../components/adsTable/AdsTable";
import AdsDisTable from "../../../components/adsDisTable/AdsDisTable";
import axios from "axios";
import { apiUrl } from "../../../constants";

const AdsDisPeopleView = () => {
  const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const [ads, setAds] = useState([]);
  const token = localStorage.getItem("token")

  const getAds = async () => {
    console.log("get ads");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/disabilityAds/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      // console.log("get dis ads response", response);
      if(response.data){
        setAds(response.data.data.DisabilityAds)
      }
    } catch (error) {
      // console.error("error get dis ads")
    }
  }

  useEffect(() => {
    getAds()
  }, [])
  return (
    <div className="container postsView">
      <div className="createButtonContainer">
        <Link
          to={"/create-dis-ads"}
          className="createButton"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          اضافة اعلان
        </Link>
      </div>
      <div className="table">
        <AdsDisTable
          columns={["اسم المنتج","تاريخ العرض","الفئة المستفيدة","الفئة العمرية","صورة المنتج","رقم الهاتف","الإجراءات"]}
          values={ads}
          onClickUpdate={() => console.log("update func")}
          onClickDelete={() => console.log("deleted")}
        />
      </div>
    </div>
  );
};

export default AdsDisPeopleView;
