import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import { AppContext } from "../../context/modeContext";
import "./style/updateAds.css";

const UpdateAds = () => {
    const [companyName, setCompanyName] = useState("");
    const [image, setImage] = useState();
    const { mode } = useContext(AppContext);
    
    return (
        <div className="createPost container">
          <h1 style={{ color: mode === "dark" ? "white" : "black" }}>تحديث اعلان</h1>
          <div className="formPost">
            <div className="row">
              <div className="col-sm-6 input-label">
                <label style={{ color: mode === "dark" ? "white" : "black" }}>
                   اسم الشركة 
                </label>
                <Input
                  type={"text"}
                  value={companyName}
                  onchange={(e) => setCompanyName(e.target.value)}
                  placeholder={"اسم الشركة"}
                  bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
                />
              </div>
    
              <div className="col-sm-6 input-label">
                <label
                  style={{ color: mode === "dark" ? "white" : "black" }}
                >صورة  الإعلان</label>
                <Input
                  type={"file"}
                  value={image}
                  onchange={(e) => setImage(e.target.value)}
                  placeholder={"صورة الإعلان"}
                  bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
                />
              </div>
            </div>
          </div>
    
          <div className="createBtn">
            <button>تحديث</button>
          </div>
        </div>
      );
}

export default UpdateAds
