import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style/talksView.css";
import { AppContext } from "../../context/modeContext";
import TalksTable from "../../components/talksTable/TalksTable";
import axios from "axios";
import { apiUrl } from './../../constants/index';

const TalksView = () => {
  const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const [talks, setTalks] = useState([]);
  const token = localStorage.getItem("token")
  const [loading, setLoading] = useState(false)

  // get talks
  const getTalks = async () => {
    console.log("get talks");
    setLoading(true)
    try {
      const response = await axios.get(`${apiUrl}/api/v1/pioneerWords`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      } )
      // console.log("response: ", response);
      if(response.data){
        setLoading(false)
        setTalks(response.data.data.words)
      }else {
        setLoading(false)
        // console.log("error in response");
      }
    } catch (error) {
      // console.error("error calling talks api")
    }
  }

  useEffect(() => {
    getTalks()
  },[])

  return (
    <div className="container postsView">
      <div className="createButtonContainer">
        <Link
          to={"/create-talks"}
          className="createButton"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          إضافة كلمة
        </Link>
      </div>
      {loading ? (
        <div className="loading my-5 mx-auto text-center">
          <h3>جاري التحميل</h3>
        </div>
      ) : null}
      <div className="table">
        <TalksTable
          columns={["صاحب الكلمة", "الكلمة", "الصورة", "الإجراءات"]}
          values={talks}
          onClickUpdate={() => console.log("update")}
          onClickDelete={() => console.log("deleted")}
        />
      </div>
    </div>
  );
};

export default TalksView;
