import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import "./style/adsDisTable.css";
import { MdDelete } from "react-icons/md";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "../../constants";
const AdsDisTable = ({ columns, values, onClickDelete, onClickUpdate }) => {
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");

  const deleteAds = async (id) => {
    console.log("delete dis ads function");
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/disabilityAds/delete-disabilityAd/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("delete response", response);
      if (response.data) {
        alert("تم حذف الإعلان");
        window.location.reload();
      }
    } catch (error) {
      // console.error("error call deleting", error);
    }
  };
  return (
    <div className="adminTable">
      <Table
        striped
        bordered
        hover
        variant={mode === "dark" ? "dark" : "light"}
      >
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th id="th" key={index}>
                <h6>{col}</h6>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values.map((val, index) => {
            return (
              <tr key={index}>
                <td>{val.productName}</td>
                <td>{val.createdAt.slice(0, 10)}</td>
                <td>{val.beneficiaryGroup}</td>
                <td>{val.ageGroup}</td>
                <td>
                  <img
                    className="tableImg"
                    src={val.image}
                    alt={val.productName}
                  />
                </td>
                <td>{val.phone}</td>
                <td>
                  <div className="icons">
                    <button
                      onClick={() => {
                        onClickDelete();
                        deleteAds(val._id);
                      }}
                    >
                      مسح
                    </button>
                    {/* <MdDelete size={25} className="delIcon" onClick={() => {
                      onClickDelete()
                      deleteAds(val._id)
                    }}/> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AdsDisTable;
