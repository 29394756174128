import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/modeContext";
import "./style/new.css";
import LatestPosts from "../../components/latestPosts/LatestPosts";
import axios from "axios";
import { apiUrl } from "../../constants";

const SpecificNew = () => {
  const { mode } = useContext(AppContext);
  const { id } = useParams();
  const [specificNews, setSpecificNews] = useState({
    title: "عنوان الخبر",
    description: "تفاصيل الخبر ",
    summary: "ملخص  الخبر",
    image: "https://blogzine.webestica.com/assets/images/blog/3by2/04.jpg",
  });
  const token = localStorage.getItem("token");

  console.log(id);

  const getSpecificPost = async () => {
    console.log("get specific post");
    const response = await axios.get(`${apiUrl}/api/v1/posts/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response);
    if (response.data) {
      setSpecificNews(response.data.post);
    }
  };

  useEffect(() => {
    getSpecificPost();
  }, []);

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
    getSpecificPost();
  }, [id]);

  // copy func
  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("تم نسخ الرابط بنجاح!");
      })
      .catch((err) => {
        console.error("فشل النسخ: ", err);
      });
  };

  return (
    <div className="specNew container">
      <div className="specNewsTitle">
        <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
          {specificNews.title}
        </h1>
      </div>
      <div className="summary">
        <p>{specificNews.summary}</p>
      </div>
      <div className="description">
        <p>{specificNews.body?.slice(0, 100)}...</p>
      </div>
      <div className="postImagee">
        <img src={specificNews.image} alt="صورة  الخبر" />
      </div>
      <div className="description">
        <p>{specificNews.body?.slice(98)}</p>
      </div>
      <div className="copy">
        <button
          onClick={() =>
            handleCopy(`https://dpo-news.com/specific-news/${id}`)
          }
        >
          نسخ
        </button>
      </div>
      <div className="latestPosts">
        <LatestPosts />
      </div>
    </div>
  );
};

export default SpecificNew;
