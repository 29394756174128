import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./style/slider.css";
import { Link } from "react-router-dom";
import { FaQuoteRight } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa6";

const Slider = () => {
  return (
    <div className="slider">
      <Carousel className="carousel">
        <Carousel.Item className="bg-dark">
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="First slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              <FaQuoteRight className="m-2" color="#2163e8" size={30} />
              مرحبا بكم في موقع DPO News يحتوي الموقع على الأقسام التالية
              <FaQuoteLeft className="m-2" color="#2163e8" size={30} />
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Second slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة آخر الأخبار المحلية <Link to={"/news/local"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              {" "}
              لمعرفة آخر الأخبار الدولية <Link to={"/news/international"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              {" "}
              لمعرفة آخر الأخبار العربية <Link to={"/news/arab"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة آخر الأخبار الرياضية <Link to={"/news/sports"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة آخر الأخبار الثقافية <Link to={"/news/Cultural"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة آخر أخبار منظمات ذوي الإعاقة <Link to={"/news/organization"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة آخر كلمات رواد التنمية <Link to={"/talks"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://img.freepik.com/free-photo/monochrome-portrait-athlete-competing-paralympic-games-championship_23-2151492797.jpg?t=st=1724146153~exp=1724149753~hmac=2d1882fafe7ac24001ccb5b5ce94ec413681857dc0c1782331977f185c8821b6&w=826"
            alt="Third slide"
          />
          <Carousel.Caption className="caption">
            <h3>
              لمعرفة الشخصيات الملهمة <Link to={"/news/inspiringPersonalities"}>اضغط هنا</Link>
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Slider;
