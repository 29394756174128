import React, { useContext, useEffect, useState } from "react";
import Input from "../../components/input/Input";
import { AppContext } from "../../context/modeContext";
import "./style/updateTalks.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";

const UpdateTalks = () => {
  const [talk, setTalk] = useState({})
  const [imageFile, setImageFile] = useState(null)
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token")
  const {id} = useParams()

  const handleUpload = async (file) => {
    // console.log("handle file upload");
    // console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("img response", response.data);
      setTalk({
        ...talk,
        image: response.data.file.path}); // Update this based on your backend's response
    } catch (error) {
      // console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const getTalk = async () => {
    // console.log("get special talk");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/pioneerWords/${id}`, {
        headers: {
          "Content-Type":"application/json",
          Authorization: `Bearer ${token}`
        }
      })
      // console.log("special talk", response.data.word);
      setTalk(response.date.word)
    } catch (error) {
      // console.error("error fetching get talk", error)
    }
  }

  useEffect(()=>{
    getTalk()
  },[id])

  const updateTalk = async () => {
    // console.log("update talk");
    try {
      const response = await axios.put(`${apiUrl}/api/v1/pioneerWords/${id}`, talk, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      // console.log("update response", response);
      
    } catch (error) {
      // console.error("error calling update api", error)
    }
    
  }

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>تحديث كلمة</h1>
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
               اسم الشخصية 
            </label>
            <Input
              type={"text"}
              value={talk?.name}
              onchange={(e) => {
                setTalk({
                  ...talk,
                  name: e.target.value
                })
              }}
              placeholder={"اسم الشخصية"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
            >صورة صاحب الكلمة</label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={"صورة الشخصية"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
                الكلمة
            </label>
            <div className="area">
              <textarea
                value={talk?.body}
                onChange={(e) => {
                  setTalk({
                    ...talk,
                    body: e.target.value
                  })
                }}
                placeholder="الكلمة"
                style={{
                  backgroundColor: mode === "dark" ? "#03346E" : "#a1a1a8",
                  color: mode === "dark" ? "white" : "black",
                }}
              />
            </div>
          </div>
        </div>

        
      </div>

      <div className="createBtn">
        <button onClick={updateTalk}>تحديث</button>
      </div>
    </div>
  );
};

export default UpdateTalks;
